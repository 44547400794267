@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,700&display=swap&subset=latin-ext');

$font-family-base: 'Nunito', sans-serif;

/* IMPORT BOOTSTRAP */
@import 'bootstrap/scss/bootstrap.scss';
@import './fa/fontawesome.scss';
@import './fa/regular.scss';
@import './fa/solid.scss';
@import './fa/brands.scss';

.plantImageThumb {
  height: 100px;
  padding: 5px;
}

.color-red {
  color: red;
}

.loading-overlay {
  background: rgba(244, 244, 244, 0.7);
  z-index: $zindex-dropdown;
}

.snapp-message {
  @include border-radius($border-radius);
}
